.footer {
  width: 100%;
  background-color: #000;
  display: flex;
}

.footerWrapper {
  width: 85%;
  display: flex;
  margin: 0 auto;
  display: -webkit-flex;
}

.followUs,
.aboutUs {
  flex: 1;
  margin: 0 20px;
  display: flex;
  margin: 0px auto;
}

.followUs,
.aboutUs {
  flex-direction: column;
  border-right: 1px solid #333;
  padding: 40px;
}
.followUs {
  padding-left: 0;
}

.otherLinks {
  flex: 1.25;
  margin: 0 20px;
  display: flex;
  margin: 0px auto;
  flex-direction: column;
  padding: 40px 40px 40px 0;
}

.followTitle {
  font-family: "Playfair Display";
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  text-align: left;
}

.allIcons {
  display: flex;
}

.iconContainer {
  width: 24px;
  height: 24px;
  margin: 20px;
  cursor: pointer;
}

.iconContainerFaceBook {
  width: 20px;
  height: 20px;
  margin: 20px 20px 20px 0px;
  cursor: pointer;
}

.aboutUsTitle {
  font-family: "Playfair Display";
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  text-align: left;
}

.aboutUsBody {
  color: #9b9b9b;
  text-align: left;
  font-family: "Chivo";
  font-weight: 300;
  line-height: 24px;
  margin: 32px auto;
}

.theUseful {
  padding-bottom: 10px;
  padding-left: 40px;
}

.theUsefulTitle,
.essentialsTitle,
.partnerEventsTitle {
  font-family: "Playfair Display";
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  text-align: left;
}

.usefulLinks {
  font-family: "Chivo";
  text-decoration: none;
  color: #9b9b9b;
  /* line-height: 32px; */
  text-align: left;
  margin: 32px auto;
}

.usefulLinksPartnerEvents {
  font-family: "Chivo";
  text-decoration: none;
  color: #9b9b9b;
  /* line-height: 32px; */
  text-align: left;
  margin: 20px auto;
}

.extraPadding {
  margin: 10px 0;
}

.misc {
  display: flex;
  border-top: 1px solid #333;
  padding: 40px;
  border-right: 1px solid #333;
}

.partnerEvents {
  display: flex;
  flex-direction: column;
}

/* .essentials,
.partnerEvents {
  padding-top: 20px;
} */

@media (max-width: 1024px) {
  .iconContainer {
    margin: 15px;
  }

  .iconContainerFaceBook {
    margin: 15px 15px 15px 0;
  }
}

@media (max-width: 905px) {
  .footerWrapper {
    flex-direction: column;
  }

  .footerWrapper {
    display: block;
  }

  .followUs {
    margin: 0;
    border-right: 0;
    border-bottom: 1px solid #333;
  }
  .aboutUs {
    padding-left: 0;
    border-right: 0;
  }

  .otherLinks {
    margin: 0;
    padding-right: 0;
    border-top: 1px solid #333;
  }
  .theUseful {
    padding-left: 0;
    padding-bottom: 20px;
  }

  .misc {
    padding: 40px 0;
    /* padding-top: 40px; */
    border-bottom: 1px solid #333;
    border-right: 0;
  }

  .essentials,
  .partnerEvents {
    flex: 1;
    padding: 0;
  }

  .usefulLinksPartnerEvents {
    margin-left: 0;
  }

  .iconContainer {
    margin: 20px;
  }
  .iconContainerFaceBook {
    margin: 20px 20px 20px 0;
  }
}
