.postWrapper {
  display: flex;
  margin: 40px auto;
  margin-bottom: 50px;
}

.dateTime {
  text-align: left;
  width: 200px;
}

.lowOpacityDateTime {
  text-align: left;
  width: 200px;
  color: #9b9b9b;
}

.boldText {
  font-weight: 700;
}

.date {
  font-weight: 900;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: left;
  flex: 1;
}

.dayTime {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
}

.articles {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  line-height: 32px;
  margin: 10px 0;
}

.postDescription {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
}

.titleWrapper {
  display: flex;
}

.webinarDescriptionWrapper {
  margin-left: 50px;
  flex: 1;
}

.buttonWrapper {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: flex-start;
}

.buttonLinkActive {
  padding: 8px 20px;
  background-color: #4503c7;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  /* height: 20px; */
}

.buttonLinkInactive {
  padding: 8px 20px;
  background-color: #ef4137;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  /* height: 20px; */
}

.notActive {
  display: none;
}

.speakerTitle {
  color: #4503c7;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}

.speakerLinks {
  text-decoration: none;
  color: black;
}

.articleLinks {
  color: black;
  text-decoration: none;
  /* border-bottom: 4px solid rgb(203, 188, 255); */
  box-shadow: inset 0 -8px 0 0 rgb(203, 188, 255, 0.5);
}

.extraPaddingRight {
  padding-right: 20px;
}

@media (max-width: 768px) {
  .postWrapper {
    flex-direction: column;
  }
  .webinarDescriptionWrapper {
    margin-left: 0;
    margin-top: 30px;
  }
  .titleWrapper {
    flex-direction: column;
  }
  .buttonWrapper {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .buttonLinkActive {
    /* width: 140px; */
    text-align: center;
    margin-bottom: 30px;
  }

  .buttonLinkInactive {
    /* width: 140px; */
    text-align: center;
    margin-bottom: 30px;
  }

  .dayTimeWrapper {
    display: flex;
    flex-direction: column;
  }

  .dayTime {
    margin-right: 50px;
  }

  .date {
    margin-bottom: 10px;
  }
  .dateTime {
    width: 100%;
  }
  .lowOpacityDateTime {
    width: 100%;
  }
}

/* @media (max-width: 500px) {
  .dayTimeWrapper {
    flex-direction: column;
  }
} */
