.heroImageWrapper {
  width: 100%;
}

.heroImage {
  width: 100%;
}

.boldFont {
  font-weight: bold;
}

.heroTextWrapper {
  background-color: #e0def3;
  margin-top: -3px;
}

p {
  margin: 0;
}

.heroText {
  font-size: 21px;
  padding: 40px 0;
  width: 60%;
  margin: auto;
  text-align: left;
  line-height: 34px;
}

.signUpLinks {
  text-decoration: none;
  /* background-color: #4503c7;
  color: white; */
  /* padding: 5px 10px;
  border-radius: 4px; */

  color: rgba(239, 64, 55);

  box-shadow: inset 0 -8px 0 0 rgba(239, 64, 55, 0.4);
}

@media (max-width: 1200px) {
  .heroText {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .imageWrapper {
    overflow: hidden;
  }
  .heroImage {
    width: 120%;
    margin: auto;
    margin-left: -20%;
  }
}
