.allPostsWrapper {
  text-align: left;
  width: 60%;
  margin: auto;
  margin-top: 100px;
}

.AllPostsTitle {
  font-size: 14px;
  font-weight: 700;
  color: #f15a24;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 250px;
}

hr {
  border-color: #f15a24;
}

.extraMargin {
  margin-top: 50px;
}

@media (max-width: 1200px) {
  .allPostsWrapper {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .AllPostsTitle {
    margin-left: 0;
  }
  .allPostsWrapper {
    width: 80%;
  }
}
